<!-- eslint-disable no-console -->
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
import api from "../../../../common/axios";
import JobStatus from "@/components/job-status";
import PreviewAddress from "@/components/preview-address-v2";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Manajemen Job",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, DatePicker, JobStatus, PreviewAddress },
  data() {
    return {
      config: {
        api: "job",
        export: "job/export",
      },
      title: "Manajemen Job",
      items: [
        {
          text: "Manajemen Job",
        },
      ],
      tabIndex: 0,
      tabStatus: null,
      filter: {
        startDate: null,
        endDate: null,
        status: null,
      },
      submitType: "",
      statusList: [
        { value: null, text: "Semua Status" },
        { value: 1, text: "On Going" },
        { value: 2, text: "Available" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      jobList: [],
      counter: {},
    };
  },
  watch: {
    currentPage() {
      this.getData();
    },
    tabIndex() {
      if (this.tabStatus === null) {
        if (this.currentPage === 1) {
          this.getData();
        } else {
          this.currentPage = 1;
        }
      } else {
        this.tabStatus = null;
      }
    },
    tabStatus() {
      if (this.currentPage === 1) {
        this.getData();
      } else {
        this.currentPage = 1;
      }
    },
  },
  methods: {
    exportExcel() {
      let _ = this;
      let statusParam = null;
      if (this.filter.status != null) {
        if (this.tabIndex == 0) {
          this.tabIndex = 0;
          this.tabStatus = null;
        } else if (this.tabIndex == 1) {
          this.tabStatus = null;
        }
      } else if (this.tabStatus === null) {
        switch (this.tabIndex) {
          case 0:
            statusParam = "all_penawaran";
            break;
          case 1:
            statusParam = "all_ongoing";
            break;
          case 2:
            statusParam = 11;
            break;
          default:
            statusParam = 12;
            break;
        }
      } else {
        statusParam = this.tabStatus;
      }

      let url = _.config.export;
      api
        .get(url, {
          params: {
            orderby: "updated_at",
            sort: "desc",
            start_date: this.filter.startDate,
            end_date: this.filter.endDate,
            key: this.filter.search,
            status: this.filter.status ? this.filter.status : statusParam,
          },
          responseType: "arraybuffer",
        })
        .then((response) => {
          if (response) {
            let blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "JobReport" + new Date();
            link.click();
          }
        });
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    submitAdvanceFilter(type) {
      this.submitType = type;
      this.$refs.submit.click();
    },
    advancedFilter() {
      if (this.submitType == "search") {
        if (this.currentPage === 1) {
          this.getData();
        } else {
          this.currentPage = 1;
        }
      } else {
        // download excel
      }
      this.$refs.modalFilter.hide();
      // this.filter.status = null;
      // this.filter.startDate = null;
      // this.filter.endDate = null;
    },
    showPopUpFilter() {
      if (this.tabIndex === 0) {
        this.statusList = [
          { value: null, text: "Semua Status" },
          { value: 1, text: "Menunggu Penawaran" },
          { value: 2, text: "Proses Negosiasi" },
          { value: 3, text: "Menunggu Pembayaran" },
        ];
      } else if (this.tabIndex === 1) {
        this.statusList = [
          { value: null, text: "Semua Status" },
          { value: 4, text: "Menunggu Assign Driver" },
          { value: 5, text: "Menunggu Dokumen" },
          { value: 6, text: "Menunggu Pick Up" },
          { value: 7, text: "Proses Pick Up" },
          { value: 8, text: "Proses Pengiriman" },
          { value: 9, text: "Unloading - Upload Dokumen" },
          { value: 10, text: "Payment - Menunggu Verifikasi Dokumen" },
        ];
      } else {
        this.statusList = [];
        this.filter.status = null;
      }
      this.$refs.modalFilter.show();
    },
    getData() {
      let statusParam = null;
      if (this.filter.status != null) {
        if (this.tabIndex == 0) {
          this.tabIndex = 0;
          this.tabStatus = null;
        } else if (this.tabIndex == 1) {
          this.tabStatus = null;
        }
      } else if (this.tabStatus === null) {
        switch (this.tabIndex) {
          case 0:
            statusParam = "all_penawaran";
            break;
          case 1:
            statusParam = "all_ongoing";
            break;
          case 2:
            statusParam = 11;
            break;
          default:
            statusParam = 12;
            break;
        }
      } else {
        statusParam = this.tabStatus;
      }

      api
        .get("/job", {
          params: {
            page: this.currentPage,
            per_page: this.perPage,
            orderby: "updated_at",
            sort: "desc",
            start_date: this.filter.startDate,
            end_date: this.filter.endDate,
            key: this.filter.search,
            status: this.filter.status ? this.filter.status : statusParam,
          },
        })
        .then((response) => {
          this.filter.status = null;
          this.jobList = response.data.data.rows;
          this.totalRows = response.data.data.total_data;
        });
    },

    reset() {
      this.filter.startDate = null;
      this.filter.endDate = null;
      this.filter.status = null;
      this.filter.search = null;
      this.$refs.modalFilter.hide();
      this.getData();
    },

    getCounter() {
      api.get("/job/counter").then((response) => {
        // console.log(response.data.data);
        this.counter = response.data.data;
      });
    },
    checkVariant(value) {
      return this.tabStatus === value ? "danger" : "outline-danger";
    },
  },

  mounted() {
    let _ = this;
    _.getData();
    _.getCounter();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <b-tabs
          align="left"
          justified
          nav-class="nav-tabs-custom"
          v-model="tabIndex"
        >
          <b-tab>
            <template v-slot:title>
              <span
                v-if="
                  counter[0].total !== 0 &&
                  counter[2].total !== 0 &&
                  counter[1].total !== 0
                "
                class="badge-notif mr-2"
                data-badge=""
                >Menunggu Penawaran &nbsp;
              </span>
              <span v-else> Menunggu Penawaran</span>
            </template>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span
                v-if="
                  counter[3].total !== 0 &&
                  counter[4].total !== 0 &&
                  counter[5].total !== 0 &&
                  counter[6].total !== 0 &&
                  counter[7].total !== 0 &&
                  counter[8].total !== 0 &&
                  counter[9].total !== 0
                "
                class="badge-notif mr-2"
                data-badge=""
                >On Going &nbsp;
              </span>
              <span v-else> On Going</span>
            </template>
          </b-tab>
          <b-tab title="Selesai"></b-tab>
          <b-tab title="Batal"></b-tab>
        </b-tabs>

        <div class="card">
          <div class="card-body">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-12">
                <div id="tickets-table_length" class="dataTables_length">
                  <div class="row" v-if="tabIndex == 0">
                    <div class="col-lg-10">
                      <b-tabs class="contianer-fluid navtab-bg" justified pills>
                        <b-tab @click="tabStatus = null">
                          <template v-slot:title>
                            <span class="font-weight-bolder">Semua</span>
                          </template>
                        </b-tab>

                        <b-tab @click="tabStatus = 1">
                          <template v-slot:title>
                            <span class="font-weight-bolder no-wr"
                              >Menunggu Penawaran &nbsp;</span
                            >
                            <span
                              v-show="counter[0].total !== 0"
                              class="badge badge-pill py-1"
                              :class="
                                tabStatus == 1 ? 'badge-light' : 'badge-primary'
                              "
                              >{{ counter[0].total }}</span
                            >
                          </template>
                        </b-tab>

                        <b-tab @click="tabStatus = 2">
                          <template v-slot:title>
                            <span class="font-weight-bolder"
                              >Proses Negosiasi &nbsp;</span
                            >
                            <span
                              v-show="counter[1].total !== 0"
                              class="badge badge-pill py-1"
                              :class="
                                tabStatus == 2 ? 'badge-light' : 'badge-primary'
                              "
                              >{{ counter[1].total }}</span
                            >
                          </template>
                        </b-tab>

                        <b-tab @click="tabStatus = 3">
                          <template v-slot:title>
                            <span class="font-weight-bolder no-wr"
                              >Menunggu Pembayaran &nbsp;</span
                            >
                            <span
                              v-show="counter[2].total !== 0"
                              class="badge badge-pill py-1"
                              :class="
                                tabStatus == 3 ? 'badge-light' : 'badge-primary'
                              "
                              >{{ counter[2].total }}</span
                            >
                          </template>
                        </b-tab>
                      </b-tabs>
                    </div>

                    <div class="col-lg-2">
                      <b-button
                        variant="primary"
                        type="button"
                        class="w-100"
                        @click="showPopUpFilter"
                        >Pencarian</b-button
                      >
                    </div>
                  </div>

                  <div class="row" v-if="tabIndex == 1">
                    <div class="col-lg-10">
                      <b-tabs
                        class="contianer-fluid navtab-bg"
                        pills
                        justified
                        align="left"
                      >
                        <b-tab @click="tabStatus = null">
                          <template v-slot:title>
                            <div class="mins text-center">
                              <span class="font-weight-bolder px-4">Semua</span>
                            </div>
                          </template>
                        </b-tab>

                        <b-tab @click="tabStatus = 4">
                          <template v-slot:title>
                            <div class="mins text-center no-wr">
                              <span class="font-weight-bolder"
                                >Menunggu Assign Driver &nbsp;</span
                              >
                              <span
                                v-show="counter[3].total !== 0"
                                class="badge badge-pill py-1"
                                :class="
                                  tabStatus == 4
                                    ? 'badge-light'
                                    : 'badge-primary'
                                "
                                >{{ counter[3].total }}</span
                              >
                            </div>
                          </template>
                        </b-tab>

                        <b-tab @click="tabStatus = 5">
                          <template v-slot:title>
                            <div class="mins text-center">
                              <span class="font-weight-bolder"
                                >Menunggu Dokumen &nbsp;</span
                              >
                              <span
                                v-show="counter[4].total !== 0"
                                class="badge badge-pill py-1"
                                :class="
                                  tabStatus == 5
                                    ? 'badge-light'
                                    : 'badge-primary'
                                "
                                >{{ counter[4].total }}</span
                              >
                            </div>
                          </template>
                        </b-tab>

                        <b-tab @click="tabStatus = 6">
                          <template v-slot:title>
                            <div class="mins text-center">
                              <span class="font-weight-bolder"
                                >Menunggu Pick Up &nbsp;</span
                              >
                              <span
                                v-show="counter[5].total !== 0"
                                class="badge badge-pill py-1"
                                :class="
                                  tabStatus == 6
                                    ? 'badge-light'
                                    : 'badge-primary'
                                "
                                >{{ counter[5].total }}</span
                              >
                            </div>
                          </template>
                        </b-tab>

                        <b-tab @click="tabStatus = 7">
                          <template v-slot:title>
                            <div class="mins text-center">
                              <span class="font-weight-bolder"
                                >Proses Pick Up &nbsp;</span
                              >
                              <span
                                v-show="counter[6].total !== 0"
                                class="badge badge-pill py-1"
                                :class="
                                  tabStatus == 7
                                    ? 'badge-light'
                                    : 'badge-primary'
                                "
                                >{{ counter[6].total }}</span
                              >
                            </div>
                          </template>
                        </b-tab>

                        <b-tab @click="tabStatus = 8">
                          <template v-slot:title>
                            <div class="mins text-center">
                              <span class="font-weight-bolder"
                                >Proses Pengiriman &nbsp;</span
                              >
                              <span
                                v-show="counter[7].total !== 0"
                                class="badge badge-pill py-1"
                                :class="
                                  tabStatus == 8
                                    ? 'badge-light'
                                    : 'badge-primary'
                                "
                                >{{ counter[7].total }}</span
                              >
                            </div>
                          </template>
                        </b-tab>

                        <b-tab @click="tabStatus = 9">
                          <template v-slot:title>
                            <div class="mins text-center">
                              <span class="font-weight-bolder no-wr"
                                >Unloading - Upload Dokumen &nbsp;</span
                              >
                              <span
                                v-show="counter[8].total !== 0"
                                class="badge badge-pill py-1"
                                :class="
                                  tabStatus == 9
                                    ? 'badge-light'
                                    : 'badge-primary'
                                "
                                >{{ counter[8].total }}</span
                              >
                            </div>
                          </template>
                        </b-tab>

                        <b-tab @click="tabStatus = 10">
                          <template v-slot:title>
                            <div class="mins text-center">
                              <span class="font-weight-bolder"
                                >Payment - Menunggu Verifikasi Dokumen
                                &nbsp;</span
                              >
                              <span
                                v-show="counter[9].total !== 0"
                                class="badge badge-pill py-1"
                                :class="
                                  tabStatus == 10
                                    ? 'badge-light'
                                    : 'badge-primary'
                                "
                                >{{ counter[9].total }}</span
                              >
                            </div>
                          </template>
                        </b-tab>
                      </b-tabs>
                    </div>

                    <div class="col-lg-2">
                      <b-button
                        variant="primary"
                        type="button"
                        class="w-100"
                        @click="showPopUpFilter"
                        >Pencarian</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-2 col-md-2">
                <b-button
                  v-if="tabIndex >= 2"
                  variant="primary"
                  type="button"
                  class="w-100"
                  @click="showPopUpFilter"
                  >Pencarian</b-button
                >
              </div>
              <!-- End search -->
            </div>

            <!-- /.modal -->
            <div class="list-job my-3">
              <!-- @click="$router.push(`/job/${job.id}/show`)" -->
              <b-card
                v-for="(job, index) in jobList"
                :key="index"
                class="border border-4 border-light mb-2"
                @click="$router.push(`/job/${job.id}/show`)"
              >
                <template v-slot:header>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div class="col-lg-6">
                      <p class="mb-0">
                        <span class="font-weight-bolder">{{ job.code }}</span> -
                        <span class="font-weight-bolder">{{
                          job.created_at | moment("DD MMM YYYY")
                        }}</span>
                      </p>
                    </div>

                    <div class="col-lg-6">
                      <JobStatus :status="job.status" />
                    </div>
                  </div>
                </template>
                <b-card-body>
                  <div
                    class="row d-flex justify-content-between align-items-center"
                  >
                    <div class="col-lg-6">
                      <p class="font-weight-bolder mb-0">
                        <span class="font-weight-bolder">
                          {{ job.logistic_company.name }}</span
                        >
                        -
                        <span class="font-weight-bolder">
                          {{ job.logistic_company.phone }}</span
                        >
                      </p>
                      <p class="font-weight-light m-0">
                        <PreviewAddress
                          :type="'pickup'"
                          :list-address="job.addresses"
                        />
                      </p>
                    </div>

                    <div class="col-lg-6">
                      <p class="font-weight-bolder mb-0">
                        <span class="font-weight-bolder">
                          {{ job.pic_name }}</span
                        >
                        -
                        <span class="font-weight-bolder">
                          {{ job.pic_phone }}</span
                        >
                      </p>
                      <p class="font-weight-light m-0">
                        <PreviewAddress
                          :type="'dropoff'"
                          :list-address="job.addresses"
                        />
                      </p>
                    </div>
                  </div>
                  <hr class="m-2" />

                  <div
                    class="justify-content-between d-flex align-items-center"
                  >
                    <p class="float-left font-weight-bolder mb-0">
                      {{ job.pic_name }} - {{ job.pic_phone }}
                    </p>
                    <p class="float-right font-weight-bolder mb-0">
                      {{ job.offer_price | currency }}
                    </p>
                  </div>
                </b-card-body>
              </b-card>

              <div
                class="row border-radius my-3 cursor-pointer job"
                v-if="jobList.length === 0"
              >
                <div class="col-sm-12 text-center font-size-24">
                  Data tidak ditemukan
                </div>
              </div>
            </div>

            <div class="row" v-if="jobList.length > 0">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal
        title="Pencarian"
        id="modal-filter"
        ref="modalFilter"
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
      >
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(advancedFilter)">
            <button ref="submit" type="submit" style="display: none">
              Submit
            </button>
            <div class="form-group row">
              <div class="col-sm-6">
                <ValidationProvider
                  name="Start Date"
                  :rules="filter.endDate ? 'required' : ''"
                  v-slot="{ errors }"
                >
                  <div class="form-group mb-3">
                    <label>Tanggal Awal</label>
                    <date-picker
                      v-model="filter.startDate"
                      :first-day-of-week="1"
                      format="MM/DD/YYYY"
                      lang="en"
                    ></date-picker>
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-sm-6">
                <ValidationProvider
                  name="End Date"
                  :rules="filter.startDate ? 'required' : ''"
                  v-slot="{ errors }"
                >
                  <div class="form-group mb-3">
                    <label>Tanggal Akhir</label>
                    <date-picker
                      v-model="filter.endDate"
                      :first-day-of-week="1"
                      format="MM/DD/YYYY"
                      lang="en"
                    ></date-picker>
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <b-form-group
              id="cari-group"
              label="Status"
              label-for="cari"
              v-if="tabIndex < 2"
            >
              <b-form-select
                class="form-control"
                v-model="filter.status"
                :options="statusList"
              ></b-form-select>
            </b-form-group>

            <div class="mb-2">
              <ValidationProvider name="Kode Job" rules="" v-slot="{ errors }">
                <b-form-group
                  id="input-group-2"
                  label="Nomor Job"
                  label-for="input-2"
                >
                  <b-form-input
                    id="input-2"
                    v-model="filter.search"
                    type="text"
                  ></b-form-input>
                  <p class="fs-6 text-danger">
                    {{ errors[0] }}
                  </p>
                </b-form-group>
              </ValidationProvider>
            </div>
            <b-button
              type="button"
              @click="submitAdvanceFilter('search')"
              variant="success"
              >Cari</b-button
            >

            <b-button
              type="reset"
              @click="reset"
              class="ml-1"
              variant="outline-danger"
              >Reset</b-button
            >

            <b-button
              type="button"
              @click="exportExcel"
              variant="primary"
              class="ml-1"
              >Download Excel</b-button
            >
          </b-form>
        </ValidationObserver>
      </b-modal>
    </div>
  </Layout>
</template>

<style scoped>
.icon-panah {
  position: absolute;
  top: 35%;
  left: 0%;
}

.card-body {
  padding: 5px 15px !important;
}
.content-slider {
  position: relative;
}
.nav-kiri {
  cursor: pointer;
  position: absolute;
  top: 45%;
  left: 5%;
}
.nav-kanan {
  cursor: pointer;
  position: absolute;
  top: 45%;
  right: 5%;
}

.total-job {
  position: absolute;
  top: 25%;
  right: 50%;
}

.job:hover {
  background-color: #f8f2f2;
  color: #f01b3c;
  font-weight: bold;
}

.no-wr {
  white-space: nowrap;
}
.mins {
  min-width: 160px !important;
}
</style>
