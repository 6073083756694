<template>
  <p class="font-weight-bolder mb-0 text-right" :class="statusColor">
    {{ statusText }}
  </p>
</template>

<script>
export default {
  name: "JobStatus",
  props: {
    status: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    statusColor() {
      let color = "";
      switch (this.status) {
        case 1:
          color = "text-orange";
          break;
        case 2:
          color = "text-biru";
          break;
        case 3:
          color = "text-orange";
          break;
        case 4:
          color = "text-merah";
          break;
        case 5:
          color = "text-biru";
          break;
        case 6:
          color = "text-biru";
          break;
        case 7:
          color = "text-biru";
          break;
        case 8:
          color = "text-biru-muda";
          break;
        case 9:
          color = "text-biru-muda";
          break;
        case 10:
          color = "text-hijau";
          break;
        case 11:
          color = "text-hijau";
          break;
        case 12:
          color = "text-merah";
          break;
        default:
          color = "text-secondary";
          break;
      }
      return color;
    },
    statusText() {
      let text = "";
      switch (this.status) {
        case 1:
          text = "Menunggu Penawaran";
          break;
        case 2:
          text = "Proses Negosiasi";
          break;
        case 3:
          text = "Menunggu Pembayaran";
          break;
        case 4:
          text = "Menunggu Assign Driver";
          break;
        case 5:
          text = "Menunggu Dokumen";
          break;
        case 6:
          text = "Menunggu Pick Up";
          break;
        case 7:
          text = "Proses Pick Up";
          break;
        case 8:
          text = "Proses Pengiriman";
          break;
        case 9:
          text = "Unloading - Upload Dokumen";
          break;
        case 10:
          text = "Payment - Menunggu Verifikasi Dokumen";
          break;
        case 11:
          text = "Selesai";
          break;
        case 12:
          text = "Batal";
          break;
        default:
          text = "-";
          break;
      }
      return text;
    },
  },
};
</script>
